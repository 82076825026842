import http from "../../utils/http"

/**
 * 获取政府文件列表
 */
export function zhengfuList(params) {
    return http({
        url: "/api/noticezheng/page",
        data: params
    })
}
/**
 * 获取政府文件详情
 */
export function zhengfuDetail(params) {
    return http({
        url: "/api/noticezheng/info",
        data: params
    })
}
